.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #161a25;
  min-height: 100vh;
  padding-bottom: 20px;
}

.First-Page {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Left-Container {
  flex: 2;
  text-align: start;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.Slide-Title {
  font-size: calc(15px + 1vmin);
  color: #1976d2;
  font-weight: 700;
  margin-top: 15px;
}

.Slide-Description {
  font-size: calc(7px + 1vmin);
}

.Right-Container {
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  padding-left: 20px;
}

.carousel {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-Title {
  font-size: calc(30px + 2vmin);
  font-weight: 800;
  color: #1976d2;
}

.App-Description {
  font-size: calc(5px + 2vmin);
  font-weight: 400;
}

.iPhoneImg {
  flex: 1;
  height: 80vh;
}

.talking-point {
  width: 30%;
  background-color: #202534;
  border-radius: 5%;
}

.talking-point-inner {
  padding: 10px;
}

.talking-point-title {
  font-size: calc(15px + 1vmin);
  font-weight: 800;
  color: #1976d2;
}

.primary-color {
  color: #1976d2;
}

.talking-point-description {
  font-size: calc(5px + 1vmin);
  font-weight: 400;
}

.talking-points > * {
  margin: 1em 0;
}

.talking-points {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.branding {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.branding > * {
  margin: 0 0.4em;
}

.reddit-icon {
  color: #ff4500;
}

.google-icon {
  color: #0F9D58;
}

.youtube-icon {
  color: #FF0000;
}

.apple-icon {
  color: white;
}

.instagram-icon {
  color: #405DE6;
}

@media only screen and (max-width: 1550px) {
  .First-Page {
    flex-direction: column;
  }
}

@media only screen and (max-width: 600px) {
  .talking-point {
    width: 100%;
  }

  .branding {
    margin-bottom: 20px;
  }
}

.bottomRow {
  display: flex;
  justify-content: space-between;
}

.privacy-policy {
  color: #1976d2;
  font-weight: bold;
  font-size: 14px;
  padding: 6px;
  border-radius: 5%;
}

.privacy-policy:hover {
  cursor: pointer;
}